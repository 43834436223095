import React from 'react';
import '../assets/css/layout.css'; 
import { useLocation, useParams } from 'react-router-dom';
import TopBar from './TopHead'; 
import SideBar from './Sidebar'; 
import Dashboard from './Dashboard';
import Campaigns from './Campaigns'; 
import Analytics from './Analytics';
import AddEmail from './AddEmail';
import Emailchecker from './Emailchecker';
import Searchdomain from './Searchdomain';
import CampaignEmails from './CampaignEmails';
import ReplyMessages  from './ReplyMessages';
import UpdateSubject  from './UpdateSubject';


const Layout = () => {
    const { campaignId } = useParams();
    const location = useLocation(); // Get the current location
    const currentPath = location.pathname.replace("/", '');
    const renderContent = () => {
        switch (location.pathname) {
            case '/campaigns':
                return <Campaigns />;
            case '/analytics':
                return <Analytics />;
            case '/add-email':
                return <AddEmail />;
            case '/reply-messages':
                return <ReplyMessages />;    
            case '/emailchecker':
                return <Emailchecker />;
            case '/update-subject':
                return <UpdateSubject />;    
            case '/search-domain':
                return <Searchdomain />;
            case `/campaign-emails/${campaignId}`:
                return <CampaignEmails />;
            case '/':
                return <Dashboard />;
            default:
                return <Dashboard />;
        }
    };

    return (
        <div className={`dashboard_mainbox ${currentPath}`}>
        <SideBar />
            <div className="dashboard_body">
                {renderContent()}
            </div>
        </div>
    );
};

export default Layout;