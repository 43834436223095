import React from 'react';
import { NavLink } from 'react-router-dom';
import { BrowserRouter as Router, Route, Routes, Outlet,Navigate } from 'react-router-dom';
const homeUrl = process.env.REACT_APP_HOME_URL;

const Dashboard = () => {
  return (
   <div>
     <div className="tophead_mainsec">
      <div className="row">
        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <div className="title_mainsec">
            <h2>Dashboard</h2>
          </div>
        </div>
      </div>
    </div>
      <div class="services_mainbox">
        <div class="row">
          <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4">
            <div class="services_box">
               <NavLink to="/emailchecker">
                <div class="icon_box">
                  <img src="/assets/img/email-validation.png" />
                </div>
                
                <div class="title_box">
                  <h3>Email Validation</h3>
                </div>
              </NavLink>
            </div>
          </div>
          
          <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4">
            <div class="services_box">
              <NavLink to="/search-domain">
                <div class="icon_box">
                  <img src="/assets/img/domain-search.png" />
                </div>
                
                <div class="title_box">
                  <h3>Domain Search</h3>
                </div>
              </NavLink>
            </div>
          </div>
          
          <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4">
            <div class="services_box">
              <NavLink to="/add-email">
                <div class="icon_box">
                  <img src="/assets/img/email-campaign.png" />
                </div>
                
                <div class="title_box">
                  <h3>Email Warmup</h3>
                </div>
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
