import React, { useState, useEffect } from 'react';
import { getSessionData } from './utils';
import { useSearchParams, Navigate } from 'react-router-dom';

const homeUrl = process.env.REACT_APP_HOME_URL;
const apiUrl = process.env.REACT_APP_API_URL;
const user = getSessionData('user');

const Campaigns = () => {
  const [campaigns, setCampaigns] = useState([]);
  const [total_campaigns, setTotalCampaigns] = useState(0);
  const [loading, setLoading] = useState(true);
  const [areAllSelected, setAreAllSelected] = useState(false);
  const [selectedCampaigns, setSelectedCampaigns] = useState([]);
  const [popupData, setPopupData] = useState({ show: false, type: '', value: '' });
  const [editPopup, setEditPopup] = useState({ show: false, subject: '', message: '' });
  const [searchParams] = useSearchParams();
  const [status, setStatus] = useState(searchParams.get('status'));

  useEffect(() => {
    const fetchCampaigns = async () => {
      const endpoint = `list-email-campaigns?user_id=${user.id}`; 
      try {
        const response = await fetch(`${apiUrl}/api/${endpoint}`);
        if (!response.ok) {
          throw new Error('Network response was not ok'); 
        }
        const data = await response.json();
        setCampaigns(data); 
        setTotalCampaigns(data.length);
      } catch (error) {
        console.error('Error fetching email campaigns:', error);
      } finally {
        setLoading(false); 
      }
    };

    if (user && user.id) {
      fetchCampaigns();
    } else {
      setLoading(false);
    }
  }, []);

  const handleSelectAllChange = (e) => {
    const checked = e.target.checked;
    setAreAllSelected(checked);
    setSelectedCampaigns(checked ? campaigns.map((c) => c.id) : []);
  };

  const handleRowSelect = (id) => {
    const updatedSelection = selectedCampaigns.includes(id)
      ? selectedCampaigns.filter((campaignId) => campaignId !== id)
      : [...selectedCampaigns, id];
    setSelectedCampaigns(updatedSelection);
    setAreAllSelected(updatedSelection.length === campaigns.length);
  };



 const handleDeleteSelected = async () => {
  if (selectedCampaigns.length === 0) {
    alert('No campaigns selected');
    return;
  }
  const isConfirmed = window.confirm("Are you sure you want to delete the selected campaigns?");
  if (!isConfirmed) return;

  try {
    setLoading(true);
    const endpoint = 'delete_campaigns'; // Use the same endpoint
    const response = await fetch(`${apiUrl}/api/${endpoint}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ ids: selectedCampaigns }), // Send the selected IDs
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    alert('Selected campaigns deleted successfully');
    // Update the local state to remove the deleted campaigns
    const remainingCampaigns = campaigns.filter(
      (campaign) => !selectedCampaigns.includes(campaign.id)
    );
    setCampaigns(remainingCampaigns);
    setSelectedCampaigns([]);
    setAreAllSelected(false);
  } catch (error) {
    console.error('Error deleting campaigns:', error);
    alert("Failed to delete campaigns. Please try again.");
  } finally {
    setLoading(false);
  }
};

  const handleDownloadCSV = () => {
    if (selectedCampaigns.length === 0) {
      alert('No campaigns selected for download');
      return;
    }

    const selectedData = campaigns.filter((campaign) =>
      selectedCampaigns.includes(campaign.id)
    );

    const csvData = selectedData.map((campaign) => ({
      Name: `${campaign.first_name} ${campaign.last_name}`,
      Email: campaign.smtp_username,
      Provider: 'SMTP',
      DailyLimit: `${campaign.total_emails} / ${campaign.daily_limit}`,
      WarmupEnabled: campaign.warmup_enabled === 'TRUE' ? 'Yes' : 'No',
      Reputation: `${campaign.warmup_emails}%`,
    }));

    const csvContent =
      'data:text/csv;charset=utf-8,' +
      [
        Object.keys(csvData[0]).join(','), // CSV headers
        ...csvData.map((row) => Object.values(row).join(',')), // CSV rows
      ].join('\n');

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'campaigns.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const handleRunCampaign = async (id) => {
    setLoading(true);
    const endpoint = 'run_campaign';
    const response = await fetch(`${apiUrl}/api/${endpoint}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ id, 'user_id': user.id }),
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    
    setLoading(false);
    const data1 = await response.json();
    console.log(data1);
    alert(data1.msg);
    window.location.reload();
    return data1;
  };

  const handleDeleteCampaign = async (id) => {
    const isConfirmed = window.confirm("Are you sure you want to delete this email account?");
    if (isConfirmed) {
      try {
        setLoading(true);
        const endpoint = 'delete_campaign';
        const response = await fetch(`${apiUrl}/api/${endpoint}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ id }),
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        alert('Email account deleted successfully');
        setLoading(false);
        const data1 = await response.json();
        window.location.reload();
        return data1;
      } catch (error) {
        setLoading(true);
        alert("Failed to delete the email account. Please try again.");
      }
    } else {
      console.log("Campaign deletion canceled.");
    }
  };

const handleUpdateLimit = (type) => {
  console.log("Opening popup for:", type); // Debugging
  const selectedCampaign = campaigns.find((campaign) => selectedCampaigns.includes(campaign.id));
  const value = selectedCampaign ? selectedCampaign[type] : ''; // Set value based on the type (daily_limit or warmup_limit)
  setPopupData({ show: true, type, value }); // Open popup with pre-filled value
};
const handlePopupChange = (e) => {
  setPopupData({ ...popupData, value: e.target.value }); // Update the value entered by the user in the popup
};

const handlePopupSubmit = async () => {
  const { type, value } = popupData;

  if (!value) {
    alert('Please enter a value');
    return;
  }

  try {
    setLoading(true);

    const endpoint = 'update-limits'; // Use the update-limits endpoint
    const response = await fetch(`${apiUrl}/api/${endpoint}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ids: selectedCampaigns, // Array of selected campaign IDs
        daily_limit: type === 'daily_limit' ? value : undefined, // Send daily_limit only if type is 'daily_limit'
        warmup_limit: type === 'warmup_limit' ? value : undefined, // Send warmup_limit only if type is 'warmup_limit'
      }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const result = await response.json();
    
    // Update the local campaigns state with the new values
    setCampaigns((prevCampaigns) => {
      return prevCampaigns.map((campaign) => {
        if (selectedCampaigns.includes(campaign.id)) {
          if (type === 'daily_limit') {
            campaign.daily_limit = value; // Update daily limit
          } else if (type === 'warmup_limit') {
            campaign.warmup_limit = value; // Update warmup limit
          }
        }
        return campaign;
      });
    });

   alert(`${type === 'daily_limit' ? 'Daily limit' : 'Warmup limit'} updated successfully`);
        setSelectedCampaigns([]);
    setAreAllSelected(false);
    setPopupData({ show: false, type: '', value: '' }); // Close popup after successful update

  } catch (error) {
    console.error('Error updating limits:', error);
    alert("Failed to update limits. Please try again.");
  } finally {
    setLoading(false);
  }
};

const handleUpdateCampaign = () => {
  const selected = campaigns.find(c => c.id === selectedCampaigns[0]);
  setEditPopup({
    show: true,
    subject: selected.subject,
    message: selected.campaign_message,
  });
};

const handleEditChange = (e) => {
  const { name, value } = e.target;
  setEditPopup({ ...editPopup, [name]: value });
};

const submitCampaignUpdate = async () => {
  try {
    setLoading(true);
    const response = await fetch(`${apiUrl}/api/update-campaign`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        ids: selectedCampaigns,
        subject: editPopup.subject,
        campaign_message: editPopup.message,
      }),
    });

    if (!response.ok) throw new Error('Failed to update');

    alert('Campaign updated successfully');
    setEditPopup({ show: false, subject: '', message: '' });
    window.location.reload();
  } catch (error) {
    alert('Failed to update campaign');
  } finally {
    setLoading(false);
  }
};


  if(!user) {
    return <Navigate to="/login" />;
  }

  if(user.warm_up_tool  === '0') {
    alert("you don't have to access this tool");
    return <Navigate to="/dashboard" />;
  }

  return (
    <div className="campaign_list test">
      <div className="tophead_mainsec">
        <div className="row">
          <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <div className="title_mainsec">
              <h2>Accounts ({total_campaigns})</h2>
            </div>
          </div>
          <div className="col-sm-12 col-md-9 col-lg-9 col-xl-9">
            <div className="status_mainsec">
              {status === 'true' && <p>Account added successfully</p>}
              {status === 'false' && <p>Account already exists</p>}
              {status === 'error' && <p className="error">Something went wrong, please try again later</p>}
            </div>
          </div>
          </div>
          <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <div className="btn_mainsec">
              <a className="btn_style m-2" href={`${homeUrl}/add-email`}>
                + Add New
              </a>
              {selectedCampaigns.length > 0 && (
              <>
                <button className="btn_style m-2" onClick={handleDeleteSelected}>
                  Delete Selected
                </button>
                <button className="btn_style m-2" onClick={handleDownloadCSV}>
                  Download CSV
                </button>
               <button className="btn_style m-2" onClick={() => handleUpdateLimit('daily_limit')}>
                  Update Daily Limit
                </button>
                <button className="btn_style m-2" onClick={() => handleUpdateLimit('warmup_limit')}>
                  Update Warmup Limit
                </button>
                  <button className="btn_style m-2" onClick={handleUpdateCampaign}>
                    Update Subject & Message
                  </button>
              </>
            )}
            </div>            
          </div>
        </div>
      </div>

      

      <div className="email_listing_mainsec">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <div className="card-body">
              <div className="table_mainsec">
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>
                          <label className="custom_checkbox">
                            <input
                              type="checkbox"
                              onChange={handleSelectAllChange}
                              checked={areAllSelected}
                            />
                            <span className="checkmark"></span>
                          </label>
                        </th>
                        <th>Name</th>
                        {user.id === 1 ? <th>User Name</th> : null}
                        <th>Email Address</th>
                        <th>Email Provider</th>
                        <th>Warmup Limit</th>
                        <th>Daily Limit</th>
                        <th>Warmup emails sent</th>
                        <th>Warmup Enabled</th>
                        <th>Reputation</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {campaigns.length > 0 ? (
                        campaigns.map((campaign) => (
                          <tr key={campaign.id}>
                            <td>
                              <label className="custom_checkbox">
                                <input
                                  type="checkbox"
                                  checked={selectedCampaigns.includes(campaign.id)}
                                  onChange={() => handleRowSelect(campaign.id)}
                                />
                                <span className="checkmark"></span>
                              </label>
                            </td>
                            <td>{campaign.first_name} {campaign.last_name}</td>
                            {user.id === 1 ? <td>{campaign.username}</td> : null}
                            <td>
                              <a href={`${homeUrl}/campaign-emails/${campaign.id}`}>
                                {campaign.smtp_username}
                              </a>
                            </td>
                            <td>{campaign.email_provider}</td>
                            <td>{campaign.warmup_limit}</td>
                            <td>{campaign.todayrunCampaign} / {campaign.daily_limit}</td>
                            <td>{campaign.total_emails}</td>
                            <td>{campaign.warmup_enabled === 'TRUE' ? 'Yes' : 'No'}</td>
                            <td>
                              <div
                                className={`budge ${ campaign.warmup_emails === 0 ? 'green' :
                                                     campaign.warmup_emails < 50 ? 'red' : 
                                                     campaign.warmup_emails < 85 ? 'orange' : 
                                                     'green'
                                }`}
                              >
                                {campaign.warmup_emails} %
                              </div>
                            </td>
                            <td>
                              <div className="action_btn">
                              {/*
                                <button className="refresh_btn" onClick={() => handleRunCampaign(campaign.id)}>
                                  <i className="fas fa-sync-alt"></i>
                                </button>
                              */}
                                <button className="remove_btn" onClick={() => handleDeleteCampaign(campaign.id)}>
                                  <i className="far fa-trash-alt"></i>
                                </button>
                              </div>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="8">No campaigns found</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
        {editPopup.show && (
        <div className="modal show fade infopopupModal d-block">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Update Message & Subject</h5>
                <button className="btn-close" onClick={() => setEditPopup({ show: false })}></button>
              </div>
              <div className="modal-body">
                <input
                  type="text"
                  className="form-control mb-3 text-dark"
                  name="subject"
                  value={editPopup.subject}
                  onChange={handleEditChange}
                  placeholder="Campaign Subject"
                />
                <textarea
                  className="form-control text-dark"
                  name="message"
                  value={editPopup.message}
                  onChange={handleEditChange}
                  placeholder="Campaign Message" 
                />
              </div>
              <div className="modal-footer">
                <div className="btn_mainsec">
                  <button className="btn_style" onClick={submitCampaignUpdate}>
                    Save Changes
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {popupData.show && (
        <div className="modal show fade d-block infopopupModal" tabIndex="-1" role="dialog">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">
                  Update {popupData.type === 'daily_limit' ? 'Daily Limit' : 'Warmup Limit'}
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => setPopupData({ show: false, type: '', value: '' })}
                ></button>
              </div>
              <div className="modal-body">
                <input
                  type="number"
                  className="form-control text-dark"
                  value={popupData.value}
                  onChange={handlePopupChange}
                  placeholder={`Enter new ${popupData.type === 'daily_limit' ? 'daily limit' : 'warmup limit'}`}
                />
              </div>
              <div className="modal-footer">
               <div className="btn_mainsec">
                  <button
                    type="button"
                    className="btn_style m-2"
                    onClick={() => setPopupData({ show: false, type: '', value: '' })}
                  >
                    Close
                  </button>
                  <button type="button" className="btn_style" onClick={handlePopupSubmit}>
                    Save Changes
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {loading && (
        <div className="loader_mainsec">
          <div className="spinner"></div>
        </div>
      )}
    </div>
  );
};

export default Campaigns;
