import React, { useState, useRef, useEffect } from 'react';
import Papa from 'papaparse';
import { getSessionData } from './utils';
import { useSearchParams, Navigate } from 'react-router-dom';
// import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
// import { GoogleLogin } from "react-google-login";
import { gapi } from "gapi-script";
const homeUrl = process.env.REACT_APP_HOME_URL;
const apiUrl = process.env.REACT_APP_API_URL;
const GOOGLE_CLIENT_ID = "942057149457-eeesoepdn2ib2pi2qacfv4ri409jqt55.apps.googleusercontent.com";

const AddEmail = () => {
  const user = getSessionData('user');
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState('');
  const [cName, setCName] = useState('Untitled Campaign');
  const [subject, setSubject] = useState('');
  const [cMsg, setCMsg] = useState('');
  const [loading, setLoading] = useState(false);
  const [first_name, setFirstname] = useState(''); 
  const [last_name, setLastname] = useState(''); 
  const [smtp_username, setSMTPusername] = useState(''); 
  const [smtp_password, setSMTPpassword] = useState(''); 
  const [smtp_host, setSMTPhost] = useState(''); 
  const [smtp_port, setSMTPport] = useState(''); 
  const [daily_limit, setDailylimit] = useState('');
  const [warmup_limit, setWarmuplimit] = useState('');
  const [warmup_enabled, setWarmupenabled] = useState('FALSE');
  const fileInputRef = useRef(null);
  const [searchParams] = useSearchParams();
  const [status, setStatus] = useState(searchParams.get('status'));

  const handleCampaignNameChange = async (e) => {
    const cname = e.target.value;
    setCName(cname);
  };

  const handleWarmupenabled = async (e) => {
    const cname = e.target.value;
    setWarmupenabled(cname);
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
    setFileName(selectedFile ? selectedFile.name : '');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!file) {
      alert("Please upload a CSV file.");
      return;
    }

    setLoading(true);

    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: async (results) => {
        const data = results.data;
        const userId = getSessionData('user')?.id;

        if (!userId) {
          alert('User ID is missing');
          setLoading(false);
          return;
        }

        const endpoint = 'email-campaign';
        try {
          const response = await fetch(`${apiUrl}/api/${endpoint}`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json',
            },
            body: JSON.stringify({ data, user_id: userId, cName: cName, subject: subject, cMsg: cMsg }),
          });

          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          const responseData = await response.json();
          if (response.ok) {
            window.location.href = `${homeUrl}/update-subject?ids=${responseData.ids}`;
            setFileName('');
            setCMsg('');
            setSubject('');
          } else {
            const errorData = await response.json();
            if (errorData.error) {
              alert(`Error: ${errorData.error}`);
            } else {
              alert("Error uploading data.");
            }
          }
        } catch (error) {
          console.error("Error:", error);
          alert("Error uploading data.");
        } finally {
          setFile(null);
          setFileName('');
          setLoading(false);
          fileInputRef.current.value = '';
        }
      },
    });
  };

  const handleSingleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const userId = getSessionData('user')?.id;
    if (!userId) {
      alert('User ID is missing');
      setLoading(false);
      return;
    }

    if(!first_name) {
      alert('Please enter first name');
      setLoading(false);
      return;
    }
    if(!last_name) {
      alert('Please enter last name');
      setLoading(false);
      return;
    }
    if(!smtp_username) {
      alert('Please enter SMTP username');
      setLoading(false);
      return;
    }
    if(!smtp_password) {
      alert('Please enter SMTP Password');
      setLoading(false);
      return;
    }
    if(!smtp_host) {
      alert('Please enter SMTP host');
      setLoading(false);
      return;
    }
    if(!smtp_port) {
      alert('Please enter SMTP port');
      setLoading(false);
      return;
    }
    if(!daily_limit) {
      alert('Please enter warmup limit');
      setLoading(false);
      return;
    }
    if(!warmup_limit) {
      alert('Please enter warmup limit');
      setLoading(false);
      return;
    }

    const endpoint = 'single-email-campaign';
    try {
      const response = await fetch(`${apiUrl}/api/${endpoint}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        body: JSON.stringify({ 
          user_id: userId, 
          cName, 
          subject, 
          cMsg,
          first_name,
          last_name,
          smtp_username,
          smtp_password,
          smtp_host,
          smtp_port,
          daily_limit,
          warmup_enabled,
          warmup_limit
        }),
      });

      if (response.ok) {
        window.location.href = `${homeUrl}/campaigns`;
        // alert("Email account added successfully!");
        setCMsg('');
        setSubject('');
        setFirstname('');
        setLastname('');
        setSMTPusername('');
        setSMTPpassword('');
        setSMTPhost('');
        setSMTPport('');
        setDailylimit('');
        setWarmupenabled(false);
        setWarmuplimit('');
      } else {
        const errorData = await response.json();
        if (errorData.error) {
          alert(`Error: ${errorData.error}`);
        } else {
          alert("Error add email account.");
        }
      }
    } catch (error) {
      console.error("Error:", error);
      alert("Error add email account.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // Load the gapi script dynamically when the component mounts
    const loadGoogleApi = () => {
      const script = document.createElement("script");
      script.src = "https://apis.google.com/js/api.js";
      script.onload = () => {
        window.gapi.load("client:auth2", initClient);
      };
      document.body.appendChild(script);
    };

    // Initialize the Google API client with your credentials
    const initClient = () => {
      window.gapi.client.init({
        clientId: GOOGLE_CLIENT_ID,
        scope: "https://www.googleapis.com/auth/gmail.send",
        response_type:'code'
      }).then(() => {
        console.log("Google API client initialized");
      }).catch((error) => {
        console.error("Error initializing Google API client:", error);
      });
    };

    loadGoogleApi();
  }, []);

  // Function to handle login and get authorization code
  const handleSignIn = () => {
    const userId = getSessionData('user')?.id;
    const clientId = GOOGLE_CLIENT_ID;
    const scope = "https://www.googleapis.com/auth/gmail.send https://www.googleapis.com/auth/gmail.modify profile email";
    // const redirectUri = "https://admin.automate-emailscale.io/api/g-suite-save-smtp";
    const redirectUri = `${apiUrl}/api/g-suite-save-smtp`;
    const state = encodeURIComponent(JSON.stringify({ user_id: userId }));
    const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=code&scope=${scope}&access_type=offline&prompt=consent&state=${state}`;
    window.location.href = authUrl;
    // window.gapi.auth2.getAuthInstance().signIn().then((googleUser) => {
    //   const authCode = googleUser.getAuthResponse().code;
    //   console.log("Authorization Code:", authCode);
    //   console.log("googleUser", googleUser);

    //   // Send the authorization code to your backend to exchange for an access token
    //   fetch("https://admin.automate-emailscale.io/api/g-suite-save-smtp", {
    //     method: "POST",
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //     body: JSON.stringify({ code: authCode }),
    //   })
    //     .then((response) => response.json())
    //     .then((data) => {
    //       console.log("Tokens received:", data);
    //     })
    //     .catch((error) => {
    //       console.error("Error exchanging authorization code:", error);
    //     });
    // }).catch((error) => {
    //   console.error("Error signing in with Google:", error);
    // });
  };

  // useEffect(() => {
  //   const initGoogleAuth = () => {
  //     window.gapi.load("auth2", () => {
  //       window.gapi.auth2.init({
  //         client_id: GOOGLE_CLIENT_ID,
  //         scope: "profile email https://www.googleapis.com/auth/gmail.send",
  //         access_type: "offline",
  //       });
  //     });
  //   };
  //   initGoogleAuth();
  //   }, []);

  //  const handleSignIn = async () => {
  //   const auth = window.gapi.auth2.getAuthInstance();
  //   const user = await auth.signIn();
  //   const token = user.getAuthResponse().access_token;
  //   const profile = user.getBasicProfile();

  //   console.log('getAuthResponse', user.getAuthResponse());
  //   const authCode = user.getAuthResponse().code;
  //   console.log("user:", user);
  //   console.log("authCode:", authCode);
  //   console.log("Access Token:", token);
  //   console.log("User Information:");
  //   console.log("Name:", profile.getName());
  //   console.log("Email:", profile.getEmail());
  //   console.log("Image URL:", profile.getImageUrl());
  //   // console.log("Access Token:", token);
  //   // Send this token to your backend to send emails
  // };

  if(!user) {
    return <Navigate to="/login" />;
  }

  if(user.warm_up_tool  === '0') {
    alert("you don't have to access this tool");
    return <Navigate to="/dashboard" />;
  }


  return (
    <>
      <div className="tophead_mainsec">
        <div className="row">
          <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <div className="title_mainsec">
              <h2>Add Email</h2>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <div className="status_mainsec">
              {status === 'true' && <p>Account added successfully</p>}
              {status === 'false' && <p>Account already exists</p>}
              {status === 'error' && <p className="error">Something went wrong, please try again later</p>}
            </div>
          </div>
        </div>
      </div>
      <div className="addnew_emails_mainsec">
        <div className="row">
          <div className="col-sm-12 col-md-5 col-lg-5 col-xl-5">
            <div className="csv_upload_boxsec">
              <form onSubmit={handleSubmit}>
                <div className="campaign_input_box">
                  <lable>Campaign Name</lable>
                  <input
                    type="text"
                    name="campaign_name"
                    className="form-control"
                    onChange={handleCampaignNameChange}
                    value={cName}
                  />
                  <br/>
                </div>

                <div className="campaign_input_box">
                  <lable>Subject</lable>
                  <input
                    type="text"
                    name="subject"
                    className="form-control"
                    onChange={(e) => setSubject(e.target.value)}
                    value={subject}
                  />
                  <br/>
                </div>

                <div className="campaign_input_box">
                  <lable>Campaign Message</lable>
                  <textarea
                    type="text"
                    name="campaign_msg"
                    className="form-control"
                    onChange={(e) => setCMsg(e.target.value)}
                    value={cMsg}
                  ></textarea>
                  <br/>
                </div>

                <div className="upload_box">
                  <input
                    type="file"
                    className="form-control"
                    onChange={handleFileChange}
                    accept=".csv"
                    ref={fileInputRef}
                  />
                  <div className="upload_txt">
                    <i className="fas fa-upload"></i>
                    <div className="contnt_txt">
                      <p>{fileName ? fileName : 'Choose a file or drag it here.'}</p>
                    </div>
                  </div>
                </div>

                {loading && (
                  <div className="loader">
                    <p>Loading...</p>
                  </div>
                )}

                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                  <div className="btn_mainsec">
                    <button type="submit" className="btn_style" disabled={loading}>
                      {loading ? "Uploading..." : "Submit"}
                    </button>
                    <a href="https://staging.automate-emailscale.io/sample_emails.csv" className="btn_style download_s_btn" download>Download Sample File</a>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="col-sm-12 col-md-1 col-lg-1 col-xl-1">
            <div className="or_textsec"><h3>OR</h3></div>
          </div>
          <div className="col-sm-12 col-md-5 col-lg-5 col-xl-5">
            <div className="gsuite_mansec">
              <div className="btn_mainsec">
                <button className="btn_style" onClick={handleSignIn}>Sign in with G-Suite</button>
                    
              </div> 
            </div>
            <div className="or_devider_sec">
              <h5>OR</h5>
            </div>
            <div className="single_email_form">
              <form onSubmit={handleSingleSubmit}>
                  <div className="campaign_input_box">
                    <lable>Campaign Name</lable>
                    <input
                      type="text"
                      name="campaign_name"
                      className="form-control"
                      onChange={handleCampaignNameChange}
                      value={cName}
                    />
                    <br/>
                  </div>

                  <div className="campaign_input_box">
                    <lable>Subject</lable>
                    <input
                      type="text"
                      name="subject"
                      className="form-control"
                      onChange={(e) => setSubject(e.target.value)}
                      value={subject}
                    />
                    <br/>
                  </div>

                  <div className="campaign_input_box">
                    <lable>Campaign Message</lable>
                    <textarea
                      type="text"
                      name="campaign_msg"
                      className="form-control"
                      onChange={(e) => setCMsg(e.target.value)}
                      value={cMsg}
                    ></textarea>
                    <br/>
                  </div>
                  <div className="campaign_input_box">
                    <lable>First Name</lable>
                    <input
                      type="text"
                      name="first_name"
                      className="form-control"
                      onChange={(e) => setFirstname(e.target.value)}
                      value={first_name}
                    />
                    <br/>
                  </div>
                  <div className="campaign_input_box">
                    <lable>Last Name</lable>
                    <input
                      type="text"
                      name="last_name"
                      className="form-control"
                      onChange={(e) => setLastname(e.target.value)}
                      value={last_name}
                    />
                    <br/>
                  </div>
                  <div className="campaign_input_box">
                    <lable>SMTP User Name</lable>
                    <input
                      type="text"
                      name="smtp_username"
                      className="form-control"
                      onChange={(e) => setSMTPusername(e.target.value)}
                      value={smtp_username}
                    />
                    <br/>
                  </div>
                  <div className="campaign_input_box">
                    <lable>SMTP Password</lable>
                    <input
                      type="text"
                      name="smtp_password"
                      className="form-control"
                      onChange={(e) => setSMTPpassword(e.target.value)}
                      value={smtp_password}
                    />
                    <br/>
                  </div>
                  <div className="campaign_input_box">
                    <lable>SMTP Host</lable>
                    <input
                      type="text"
                      name="smtp_host"
                      className="form-control"
                      onChange={(e) => setSMTPhost(e.target.value)}
                      value={smtp_host}
                    />
                    <br/>
                  </div>
                  <div className="campaign_input_box">
                    <lable>SMTP Port</lable>
                    <input
                      type="text"
                      name="smtp_port"
                      className="form-control"
                      onChange={(e) => setSMTPport(e.target.value)}
                      value={smtp_port}
                    />
                    <br/>
                  </div>
                  <div className="campaign_input_box">
                    <lable>Daily Limit</lable>
                    <input
                      type="text"
                      name="daily_limit"
                      className="form-control"
                      onChange={(e) => setDailylimit(e.target.value)}
                      value={daily_limit}
                    />
                    <br/>
                  </div>
                  <div className="campaign_input_box">
                    <label class="custom_checkbox">
                      <span className="label_txt">Warmup Enabled</span>
                      <input
                        type="checkbox"
                        name="warmup_enabled"
                        className=""
                        onChange={handleWarmupenabled}
                        value="TRUE"
                      />
                      <span class="checkmark"></span>
                    </label>
                    <br/>
                  </div>
                  <div className="campaign_input_box">
                    <lable>Warmup Limit</lable>
                    <input
                      type="text"
                      name="warmup_limit"
                      className="form-control"
                      onChange={(e) => setWarmuplimit(e.target.value)}
                      value={warmup_limit}
                    />
                    <br/>
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <div className="btn_mainsec">
                      <button type="submit" className="btn_style" disabled={loading}>
                        {loading ? "Uploading..." : "Submit"}
                      </button>
                    </div>
                  </div>
              </form>
            </div>
          </div>
          {loading ? <div className="loader_mainsec">
            <div className="spinner"></div>
          </div> : <div></div>}
        </div>
      </div>
    </>
  );
};

export default AddEmail;
